import { AfterViewInit, Component, computed, EventEmitter, input, output, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnergyLabel, EnergyLabelConnection } from '../../models/energy-label.model';
import { EnergyLabelService } from '../../services/energy-label.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { EVEstateType } from '../../../shared/enums/estatetype.enum';

@Component({
    selector: 'search-energy-connections',
    imports: [SharedModule],
    templateUrl: './search-energy-connections.component.html',
    styleUrl: './search-energy-connections.component.scss'
})
export class SearchEnergyConnectionsComponent implements AfterViewInit {
    energyLabel = input<EnergyLabel>();
    selectedEnergyLabelConnection = output<EnergyLabelConnection>();

    attestInfo = computed(() => this.energyLabel()?.functionalUnitNumber + ' ' + this.energyLabel()?.buildingNumber + ', ' + this.energyLabel()?.streetAddress + ', ' + this.energyLabel()?.postCode);

    query = new FormControl<string>('');
    searchForm = this.fb.group({ query: this.query });
    searchResult: EnergyLabelConnection[] = [];
    loading: boolean = false;
    EVEstateType = EVEstateType;

    constructor(private fb: UntypedFormBuilder, public energyLabelService: EnergyLabelService, private toastService: ToastService) { }

    search(): void {
        this.searchResult = [];

        if (!this.query.value) {
            return;
        }

        this.loading = true;
        this.energyLabelService.searchEnergyLabelConnections(this.query.value)
            .pipe(
                catchError(error => {
                    console.error(error);
                    this.toastService.setMessage({ text: 'Noe feil har skjedd ved søk. Prøv igjen', type: 'error' });
                    this.loading = false;
                    return of({});
                })
            )
            .subscribe(res => {
                this.searchResult = res as EnergyLabelConnection[];
                this.loading = false;
            },
            );
    }

    selectEnergyConnection(connection: EnergyLabelConnection) {
        this.selectedEnergyLabelConnection.emit(connection);
    }

    ngAfterViewInit(): void {
        const searchstring = this.energyLabel()?.streetAddress + ' ' + this.energyLabel()?.postCode;
        if (searchstring != null) {
            this.query.setValue(searchstring);
            this.search();
        }
    }
}
