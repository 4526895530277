import { AfterViewInit, Component, EventEmitter, input, Input, output, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EvAccordionComponent } from 'ev-accordion';
import { SharedModule } from 'src/app/shared/shared.module';
import { EnergyLabelSearch } from '../../models/energy-label.model';
import { EnergyLabelService } from '../../services/energy-label.service';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
    selector: 'search-energy',
    imports: [SharedModule],
    templateUrl: './search-energy.component.html',
    styleUrl: './search-energy.component.scss'
})
export class SearchEnergyComponent implements AfterViewInit {

    searchstring = input<string>();
    selectedEnergy = output<string>();

    query = new FormControl<string>('');
    searchForm = this.fb.group({ query: this.query });
    searchResult: EnergyLabelSearch[] = [];
    loading: boolean = false;

    constructor(private fb: UntypedFormBuilder, private energyLabelService: EnergyLabelService, private toastService: ToastService
    ) { }


    search(): void {
        this.searchResult = [];

        if (!this.query.value) {
            return;
        }

        this.loading = true;
        this.energyLabelService.searchEnergyLabel(this.query.value)
            .pipe(
                catchError(error => {
                    console.error(error);
                    this.toastService.setMessage({ text: 'Noe feil har skjedd ved søk. Prøv igjen', type: 'error' });
                    this.loading = false;
                    return of({});
                })
            )
            .subscribe(res => {
                this.searchResult = res as EnergyLabelSearch[];
                this.loading = false;
            }
            );
    }

    selectEnergy(option: string) {
        this.selectedEnergy.emit(option);
    }

    ngAfterViewInit(): void {
        if (this.searchstring() != null && this.searchstring() != '') {
            this.query.setValue(this.searchstring());
            this.search();
        }
    }
}
