<div class="header section">
    <div class="top-buttons">
        <div class="left-side">
            <button (click)="createNewMode()">Ny energiattest</button>
            <button (click)="showSearchEnergyDialog = true">Søk</button>
            <button (click)="getNextFromWorkQueue()" [attr.disabled]="currentWorkQueueItem != null || loadingNextWorkqueueItem || !remainingWorkqueue ? true : null">
                {{ "Neste fra Workqueue (" + remainingWorkqueue + ")" }}
            </button>
            
            <ev-loader [class.hidden]="!loading && !loadingNextWorkqueueItem" [size]="30"></ev-loader>
        </div>
        <div class="right-side">
            @if (hasExpertRole()) {
                <button class="secondary-btn" (click)="putCommonWorkQueueProcessed()" [attr.disabled]="!currentWorkQueueItem || loadingNextWorkqueueItem ? true : null">Slett fra Workqueue</button>
            }
            @else {
                <button class="secondary-btn" (click)="putCommonWorkQueueHold()" [attr.disabled]="!currentWorkQueueItem || loadingNextWorkqueueItem ? true : null">Sett til ekspert</button>
            }
        </div>
    </div>
    
    <div class="workqueue-info">{{ currentWorkQueueItem?.instruction }}</div>
</div>

<div class="energy-performance-certificate-form section">
    <div class="left-column">
        <div class="section">
            <div class="validation entry important mandatory">
                <div class="underlined">
                    <div class="blue-bar"></div>
                    <ev-key-value-pair [width]="'30%'" [key]="'Attestnummer'" [borderless]="true">
                        <ev-input-reactive
                            [height]="40"
                            [hideReset]="true"
                            [formCtrl]="energyPerformanceCertificateForm.get('energyLabelID')"
                        ></ev-input-reactive>
                    </ev-key-value-pair>
                </div>
                @if (energyPerformanceCertificateForm.errors?.['energyLabelID'].invalid) {
                    <div class="error icon"></div>
                }
            </div>
            <div class="validation entry important mandatory">
                <div class="underlined">
                    <div class="blue-bar"></div>
                    <ev-key-value-pair [key]="'Utsedt dato'" [borderless]="true">
                        <ev-material-datepicker 
                            [lineHeight]="'40px'" 
                            [formControlNameInput]="'issueDate'" 
                            [parentForm]="energyPerformanceCertificateForm" 
                            [isRegular]="true">
                        </ev-material-datepicker>
                    </ev-key-value-pair>
                </div>
                @if (energyPerformanceCertificateForm.get('issueDate').invalid) {
                    <div class="error icon"></div>
                }
            </div>
            <div class="validation entry important mandatory">
                <div class="underlined">
                    <div class="blue-bar"></div>
                    <ev-key-value-pair [key]="'Energikarakter'" [borderless]="true">
                        @if (energyLabelItems.length && !loading) {
                            <ev-dropdown-reactive 
                                [height]="'40px'"
                                [items]="energyLabelItems" 
                                [parentForm]="energyPerformanceCertificateForm" 
                                [formControlNameInput]="'energyLabel'">
                            </ev-dropdown-reactive>
                        }
                    </ev-key-value-pair>
                </div>
            </div>
            <div class="validation entry important mandatory">
                <div class="underlined">
                    <div class="blue-bar"></div>
                    <ev-key-value-pair [key]="'Oppvarmningsfarge'" [borderless]="true">
                        @if (energyColorItems.length && !loading) {
                            <ev-dropdown-reactive 
                                [height]="'40px'"
                                [items]="energyColorItems" 
                                [parentForm]="energyPerformanceCertificateForm" 
                                [formControlNameInput]="'energyLabelingHeatingColorID'">
                            </ev-dropdown-reactive>
                        }
                    </ev-key-value-pair>
                </div>
            </div>
            <div class="validation entry important mandatory">
                <div class="underlined">
                    <div class="blue-bar"></div>
                    <ev-key-value-pair [key]="'Input type'" [borderless]="true">
                        @if (certificateMethodItems.length && !loading) {
                            <ev-dropdown-reactive 
                                [height]="'40px'" 
                                [items]="certificateMethodItems" 
                                [parentForm]="energyPerformanceCertificateForm" 
                                [formControlNameInput]="'energyLabelCertificateMethodID'">
                            </ev-dropdown-reactive>
                        }
                    </ev-key-value-pair>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="entry">
                <ev-key-value-pair [key]="'Byggeår'" [variant]="'grey'">
                    <ev-input-reactive
                        [type]="'number'"
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('buildYear')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Bygningskategori'" [variant]="'grey'">
                    @if (buildingCategoryItems.length && !loading) {
                        <ev-dropdown-reactive
                            [items]="buildingCategoryItems" 
                            [parentForm]="energyPerformanceCertificateForm" 
                            [formControlNameInput]="'energyBuildingCategoryID'">
                        </ev-dropdown-reactive>
                    }
                </ev-key-value-pair>
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Byggematerialer'" [variant]="'grey'">
                    @if (buildingMaterialItems.length && !loading) {
                        <ev-dropdown-reactive 
                            [items]="buildingMaterialItems" 
                            [parentForm]="energyPerformanceCertificateForm" 
                            [formControlNameInput]="'buildingMaterialID'">
                        </ev-dropdown-reactive>   
                    }
                </ev-key-value-pair>
            </div>
        </div>
        <div class="section">
            <div class="entry">
                <ev-key-value-pair [key]="'Gateadresse'" [variant]="'grey'" [width]="'150px'">
                    <ev-input-reactive
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('streetAddress')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Postnummer'" [variant]="'grey'">
                    <ev-input-reactive
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('postCode')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Bygningsnummer'" [variant]="'grey'">
                    <ev-input-reactive
                        [type]="'number'"
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('buildingNumber')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Bruksenhetsnummer'" [variant]="'grey'">
                    <ev-input-reactive
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('functionalUnitNumber')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Brl orgnr'" [variant]="'grey'">
                    <ev-input-reactive
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('organizationNumber')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
                @if (energyPerformanceCertificateForm.get('organizationNumber').invalid) {
                    <div class="error icon"></div>
                }
            </div>
            <div class="entry">
                <ev-key-value-pair [key]="'Brl andelsnr'" [variant]="'grey'">
                    <ev-input-reactive
                        [hideReset]="true"
                        [formCtrl]="energyPerformanceCertificateForm.get('shareNumber')"
                    ></ev-input-reactive>
                </ev-key-value-pair>
                @if (energyPerformanceCertificateForm.get('shareNumber').invalid) {
                    <div class="error icon"></div>
                }
            </div>
        </div>
    </div>
    <div class="right-column">
        <div class="connections-wrapper">
            <div class="entry important">
                <div class="underlined">
                    <ev-key-value-pair [key]="'Koblet til eiendom, bygning eller bruksenhet'" [width]="'70%'" [borderless]="true">
                        <button [attr.disabled]="loading || inReadOnlyMode ? true : null" (click)="showSearchEnergyLabelConnectionsDialog = true">Koble til ny</button>
                    </ev-key-value-pair>
                </div>
            </div>

            <div class="connections">
                @for (connection of connections; track connection) {
                    <div class="added-connection">
                        <div>{{ getConnectionInfo(connection) }}</div>
                        <button class="light" (click)="removeConnection(connection)" [attr.disabled]="loading || inReadOnlyMode ? true : null">Fjern</button>
                    </div>
                }
            </div>

            <div class="map-wrapper">
                <static-map [mapMarkers]="selectedConnectionMapMarkers" [index]="1"></static-map>
            </div>
        </div>
    </div>
</div>

<div class="bottom-buttons">
    <div class="buttons-wrapper">
        <button class="edit" (click)="edit()" [attr.disabled]="loading || inCreateMode || !inReadOnlyMode ? true : null">Endre</button>
        <button class="save" (click)="save()" [attr.disabled]="loading || energyPerformanceCertificateForm.invalid || inReadOnlyMode ? true : null">Lagre</button>
        <button class="secondary-btn" (click)="cancel()" [attr.disabled]="inReadOnlyMode ? true : null">Avbryt</button>
    </div>
</div>

<ev-dialog [title]="'Søk etter energiattest'" [theme]="'green'" [visible]="showSearchEnergyDialog" (close)="showSearchEnergyDialog = false" [hideFooter]="true">
     @if (showSearchEnergyDialog) {<search-energy (selectedEnergy)="onEnergySelected($event)"></search-energy>}
</ev-dialog>
<ev-dialog [title]="'Søk etter eiendom, bygning eller bruksenhet'" [theme]="'green'" [visible]="showSearchEnergyLabelConnectionsDialog" (close)="showSearchEnergyLabelConnectionsDialog = false" [hideFooter]="true">
    @if (showSearchEnergyLabelConnectionsDialog) {<search-energy-connections (selectedEnergyLabelConnection)="onConnectionSelected($event)" [energyLabel]="this.loadedEnergyLabel"></search-energy-connections>}
</ev-dialog>
