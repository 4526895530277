<div class="attest-info">{{attestInfo()}}</div>

<div class="search-wrapper">
    <ev-input-reactive type="search" [formCtrl]="searchForm.get('query')" (keyup.enter)="search()"></ev-input-reactive>
    <button class="search" (click)="search()"></button>
</div>

<div class="search-result-wrapper">
  @if (loading) {
    <div class="loader">
      <ev-loader [size]="110"></ev-loader>
    </div>
  }

    @if (searchResult.length > 0) {

        <div class="column-wrapper column-title">
            <div class="column">Type</div>
            <div class="column">Kobling</div>
        </div>
        
        @for (result of searchResult; track $index) {
            <div class="column-wrapper clickeble" [ngClass]="{'highlight': result.highlight}" (click)="selectEnergyConnection(result)">
                <div class="column">
                    @if (result.estateTypeName) {
                        <div class="estate-icon" [ngClass]="EVEstateType[result.estateTypeID]"></div>
                    }
                </div>
                
                @if (result.lvl === 1) {
                    <div class="column">{{ result.unitInfo }}</div>
                }
                @if (result.lvl === 2) {
                    <div class="column lvl2">{{ result.buildingInfo }}</div>
                }
                @if (result.lvl === 3) {
                    <div class="column lvl3">{{ result.functionalUnitInfo }}</div>
                }
            </div>
        } 
    }
</div>
